import { useQuery } from "@tanstack/react-query";
// import { endpoints } from "../endpoints/endpoints";
// import axiosInstance from "../utilites/axios";
// import moment from "moment-timezone";
import axios from "axios";
import axiosInstance from "../utilites/axios";
import moment from "moment";
import { endpoints } from "../endpoints/endpoints";

/*

  Change the date statically to get previous data
  limit=100&date=2024-05-28

  Change the date dynamically to get current data
  limit=100&date=${moment().tz("America/New_York").format("yyyy-MM-DD")}

*/

export const useGetShippingOrderList = ({ query }) => {
  return useQuery({
    queryKey: ["shippingOrderList", query],
    queryFn: async () => {
      return await axios
        .get(
          `https://www.listapp.info/admin/metrics/shipping.php?q=${query}&t=${new Date().getTime()}`
        )
        .then((res) => {
          if (res?.status === 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log("error", e);
        });
    },
    // refetchInterval: 180000,
    staleTime: 0,
    casheTime: 0,
    refetchInterval: 300000, // 5 minutes
    onSuccess: ({ data }) => {
      return data;
    },
  });
};

export const useGetShippingDueList = ({ query }) => {
  return useQuery({
    queryKey: ["shippingDueList", query],
    queryFn: async () => {
      return await axios
        .get(
          `https://www.listapp.info/admin/metrics/shipping.php?q=${query}&t=${new Date().getTime()}`
        )
        .then((res) => {
          if (res?.status === 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log("error", e);
        });
    },
    // refetchInterval: 180000,
    staleTime: 0,
    casheTime: 0,
    refetchInterval: 300000, // 5 minutes
    onSuccess: ({ data }) => {
      return data;
    },
  });
};

/**
 * The function `useGetShippingPickedShelvingList` fetches shipping data based on a query using axios
 * and returns the data with a refetch interval of 5 minutes.
 * @returns The `useGetShippingPickedShelvingList` function returns a custom hook that uses `useQuery`
 * from a library like React Query to fetch shipping data from a specific API endpoint based on the
 * provided query parameter. The function sets up the queryKey, queryFn to make the API call, and
 * includes options like staleTime, cacheTime, refetchInterval, and onSuccess callback. The
 */
export const useGetShippingPickedShelvingList = ({ query }) => {
  return useQuery({
    queryKey: ["shippingDueList", query],
    queryFn: async () => {
      return await axios
        .get(
          `https://www.listapp.info/admin/metrics/shipping.php?q=${query}&t=${new Date().getTime()}`
        )
        .then((res) => {
          if (res?.status === 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log("error", e);
        });
    },
    // refetchInterval: 180000,
    staleTime: 0,
    casheTime: 0,
    refetchInterval: 300000, // 5 minutes
    onSuccess: ({ data }) => {
      return data;
    },
  });
};

export const useGetShippingEndingList = ({ query }) => {
  return useQuery({
    queryKey: ["shippingEndingList", query],
    queryFn: async () => {
      // function randomizeObjectValues(obj) {
      //   for (let key in obj) {
      //     if (obj.hasOwnProperty(key)) {
      //       obj[key] = Math.floor(Math.random() * 201); // Random value between 0 and 200
      //     }
      //   }
      //   return obj;
      // }

      // const data = {
      //   todayEnd: 91,
      //   tomorrowEnd: 75,
      //   ground: 148,
      //   freight: 18,
      // };

      // return randomizeObjectValues(data);
      return await axios
        .get(
          `https://www.listapp.info/admin/metrics/shipping.php?q=${query}&t=${new Date().getTime()}`
        )
        .then((res) => {
          if (res?.status === 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log("error", e);
        });
    },
    // refetchInterval: 300000,
    staleTime: 0,
    casheTime: 0,
    refetchInterval: 1000, // 5 minutes
    onSuccess: ({ data }) => {
      return data;
    },
  });
};

// export const useGetTestingList = ({ limit }) => {
//   return useQuery({
//     queryKey: ["testList", limit],
//     queryFn: async () => {
//       return await axiosInstance
//         .get(
//           `${endpoints.testing}?limit=100&date=${moment()
//             .tz("America/New_York")
//             .format("yyyy-MM-DD")}`
//         )
//         .then((res) => {
//           if (res?.status === 200) {
//             return res?.data;
//           } else {
//             return [];
//           }
//         })
//         .catch((e) => {
//           // console.log("error", e);
//         });
//     },
//     refetchInterval: 500 * 10,
//     onSuccess: ({ data }) => {
//       return data;
//     },
//   });
// };

// export const useGetTestingingDetails = ({ uid }) => {
//   return useQuery({
//     queryKey: ["testingDetails", uid],
//     queryFn: async () => {
//       return await axiosInstance
//         .get(
//           `${endpoints.testing}/details?uid=${uid}&date=${moment()
//             .tz("America/New_York")
//             .format("yyyy-MM-DD")}`
//         )
//         .then((res) => {
//           if (res?.status === 200) {
//             return res?.data;
//           } else {
//             return [];
//           }
//         })
//         .catch((e) => {
//           // console.log(e);
//         });
//     },
//     refetchInterval: 500 * 10,
//     onSuccess: ({ data }) => {
//       return data;
//     },
//   });
// };

/**
 * The function `useGetAllShippingList` fetches a list of all shipping items with a specified limit
 * using a query function and axiosInstance.
 * @returns The `useGetAllShippingList` function is returning a custom hook that uses `useQuery` from
 * an unknown library. The hook makes an asynchronous request to fetch a list of shipping items from a
 * specific API endpoint. It includes a query key based on the limit provided, a query function that
 * fetches the data using Axios, a refetch interval of 5000 milliseconds, and an onSuccess callback
 */
export const useGetAllShippingList = ({ limit }) => {
  return useQuery({
    queryKey: ["allShippingList", limit],
    queryFn: async () => {
      return await axiosInstance
        .get(
          `${endpoints.shipping}?limit=100&date=${moment()
            .tz("America/New_York")
            .format("yyyy-MM-DD")}`
        )
        .then((res) => {
          if (res?.status === 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          // console.log("error", e);
        });
    },
    refetchInterval: 500 * 10,
    onSuccess: ({ data }) => {
      return data;
    },
  });
};

/**
 * The function `useGetShippingDetails` fetches shipping details based on a user ID with a specified
 * refetch interval and success handler.
 * @returns The `useGetShippingDetails` function is returning a custom hook that uses `useQuery` from a
 * library like React Query. This hook fetches shipping details data from an API endpoint using Axios.
 * It includes configuration options such as `queryKey`, `queryFn`, `enabled`, `refetchInterval`, and
 * `onSuccess`. The function returns the result of the `useQuery` hook.
 */
export const useGetShippingDetails = ({ uid }) => {
  return useQuery({
    queryKey: ["shippingDetails", uid],
    queryFn: async () => {
      return await axiosInstance
        .get(
          `${endpoints.shipping}/details?uid=${uid}&date=${moment()
            .tz("America/New_York")
            .format("yyyy-MM-DD")}`
        )
        .then((res) => {
          if (res?.status === 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          // console.log(e);
        });
    },
    enabled: Boolean(uid),
    refetchInterval: 500 * 10,
    onSuccess: ({ data }) => {
      return data;
    },
  });
};
