import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DonutsCard from "./sub-components/donutCards";
import { ToQuickListSkeleton } from "./sub-components/skeleton";
import DoubleLayeredDonutChart from "./sub-components/doubleChart";
import DonutsCard1 from "./sub-components/donutCards1";

const ToQuickList = ({
  currentPage,
  setCurrentPage,
  allScreenList,
  isScreeningLoading,
}) => {
  const navigate = useNavigate();

  const handleChangePage = (event) => {
    setCurrentPage((prev) => prev + 1);
  };

  return (
    <>
      <p className="secondHeader">Screening</p>
      <Row>
        {isScreeningLoading ? (
          <ToQuickListSkeleton />
        ) : allScreenList?.users === undefined ||
          allScreenList?.users === null ||
          allScreenList?.users?.length === 0 ||
          !allScreenList?.users ? (
          <>
            <img
              alt="No data found"
              src={"no-data.gif"}
              style={{
                width: "200px",
                display: "block",
                margin: "0 auto",
                objectFit: "contain",
              }}
            />
          </>
        ) : (
          <>
            {/* {allScreenList?.users?.map((user, i) => ( */}
            <Col
              xl={4}
              xxl={4}
              lg={4}
              md={6}
              xs={12}
              sm={12}
              key={1}
              // onClick={() => {
              //   navigate("/exp-temp/details-view", { state: user });
              // }}
              style={{
                cursor: "pointer",
              }}
            >
              <DonutsCard
                key={1}
                left={"Daily Average"}
                last={"High Score"}
                user={
                  !!allScreenList &&
                  allScreenList.users.length > 0 &&
                  allScreenList?.users[0]
                }
              />
            </Col>
            <Col
              xl={4}
              xxl={4}
              lg={4}
              md={6}
              xs={12}
              sm={12}
              key={2}
              // onClick={() => {
              //   navigate("/exp-temp/details-view", { state: user });
              // }}
              style={{
                cursor: "pointer",
              }}
            >
              <DonutsCard1
                key={2}
                left={"Daily Average"}
                last={"High Score"}
                user={
                  !!allScreenList &&
                  allScreenList.users.length > 0 &&
                  allScreenList?.users[1]
                }
              />
            </Col>
            {/* ))} */}
          </>
        )}
        {/* <DoubleLayeredDonutChart /> */}
      </Row>
      {/* {allScreenList?.pages[allScreenList?.pages.length - 1]?.limit *
        allScreenList?.pages[allScreenList?.pages.length - 1]?.current <
        allScreenList?.pages[allScreenList?.pages.length - 1]?.totalCount && (
        <p
          className="secondHeader"
          style={{
            cursor: "pointer",
          }}
          onClick={handleChangePage}
        >
          Load More
        </p>
      )} */}
      {allScreenList?.users?.length === 0 ||
        (allScreenList?.users?.length !== allScreenList?.totalCount && (
          <p
            className="secondHeader"
            style={{
              cursor: "pointer",
            }}
            onClick={handleChangePage}
          >
            Load More
          </p>
        ))}
    </>
  );
};

export default ToQuickList;
// checked
