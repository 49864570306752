import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import "../../../utilites/Card.css";
// import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReactApexChart from "react-apexcharts";
import DoubleRingDonutChart from "../../../pages/exp-temp/DoubleRingDonutChart";
const DonutsCard = (props) => {
  

const dataSet = {
  "department": "Screening",
  "target": {
    "maxval": 90,
    "avgtime": 20,
    "valcolor": [
      {
        "min": 0,
        "max": 40,
        "color": "#259216"
      },
      {
        "min": 41,
        "max": 80,
        "color": "#ff5733"
      },
      {
        "min": 81,
        "max": 100,
        "color": "#c2c00d"
      }
    ],
    "timecolor": [
      {
        "min": 0,
        "max": 40,
        "color": "#ff0000"
      },
      {
        "min": 41,
        "max": 80,
        "color": "#00ff00"
      },
      {
        "min": 81,
        "max": 100,
        "color": "#0000ff"
      }
    ]
  },
  "users": [
    {
      "id": 179,
      "target": {
        "maxval": 100,
        "avgtime": 30,
        "valcolor": [
          {
            "min": 0,
            "max": 40,
            "color": "#ff5733"
          },
          {
            "min": 41,
            "max": 80,
            "color": "#c2c00d"
          },
          {
            "min": 81,
            "max": 100,
            "color": "#259216"
          }
        ],
        "timecolor": [
          {
            "min": 0,
            "max": 40,
            "color": "#ff0000"
          },
          {
            "min": 41,
            "max": 80,
            "color": "#00ff00"
          },
          {
            "min": 81,
            "max": 100,
            "color": "#0000ff"
          }
        ]
      },
      
    },
    {
      "id": 93,
      "target": {
        "maxval": 30,
        "avgtime": 20,
        "valcolor": [
          {
            "min": 0,
            "max": 30,
            "color": "#ff0000"
          },
          {
            "min": 31,
            "max": 50,
            "color": "#00ff00"
          },
          {
            "min": 51,
            "max": 100,
            "color": "#0000ff"
          }
        ],
        "timecolor": [
          {
            "min": 0,
            "max": 20,
            "color": "#ff0000"
          },
          {
            "min": 21,
            "max": 40,
            "color": "#00ff00"
          },
          {
            "min": 41,
            "max": 100,
            "color": "#0000ff"
          }
        ]
      },
      
    }
  ]
}

const getObjectById=(id)=> {
  for (let i = 0; i < dataSet.users.length; i++) {
    if (dataSet.users[i].id === id) {
      return dataSet.users[i];
    }
  }
  return null;
}



function mapValueToPercentage(value, minValue, maxValue) {
  if (value < minValue || value > maxValue) {
    throw new Error(`Value ${value} is out of the valid range [${minValue}, ${maxValue}]`);
  }

  return ((value - minValue) / (maxValue - minValue)) * 100;
}

function getColorForValue(value,ranges) {
  // Special case: if value is exactly 100, return the color for max range (100%)
  if (value > 100) {
    const maxRange = ranges.find(range => range.max === 100);
    if (maxRange) {
      return maxRange.color;
    }
  }

  // Loop through the ranges to find where the value falls
  for (let range of ranges) {
    if (value >= range.min && value <= range.max) {
      // Map the value to percentage within this range
      const mappedPercentage = mapValueToPercentage(value, range.min, range.max);
      console.log(`Mapped Percentage: ${mappedPercentage}%`);
      return range.color;
    }
  }

  return null;  // Return null if the value does not fall within any range
}

useEffect(()=>{

  const matchedObject = getObjectById(199);
  if(matchedObject)
  {
  setStaticVal(matchedObject.target.maxval)
  setStaicTiming(matchedObject.target.avgtime)
  setColorCodeValue(getColorForValue(fillValue1,matchedObject.target.valcolor),"getColorForValue")
  }
  else{
    setStaticVal(dataSet.target.maxval)
    setStaicTiming(dataSet.target.avgtime)
    setColorCodeValue(getColorForValue(fillValue1,dataSet.target.valcolor))
  }


},[dataSet,props.user])





  const fillValue1 = 90;

  const [staticVal, setStaticVal] = useState(0)
  const [staticTiming, setStaicTiming] = useState(0)
  const [colorCodeValue, setColorCodeValue] = useState("")

  const [fillValue2, setFillValue2] = useState(35);

  const [chartWidth, setChartWidth] = useState(300);
  const [chartHeight, setChartHeight] = useState(300);

  const staticDataOuter = [
    { label: "Base", value: fillValue1, color: `${colorCodeValue}` },
    {
      label: "Static",
      value: staticVal >= fillValue1 ? staticVal - fillValue1 : 0,
      color: "#ccc",
    },
  ];

  const staticDataInner = [
    {
      label: "Base",
      value: fillValue2,
      color: fillValue2 > 0 ? "#00bfff" : "#ccc",
      borderColor: fillValue2 == 0 ? "#00bfff" : "#ccc",
    },
    {
      label: "Static",
      value: fillValue2 > staticTiming ? fillValue2 - staticTiming : 0,
      color: "#ccc",
      borderColor: "#ccc",
    },
  ];
  return (
    <>
      <Card className="endingStyle">
        {/* {props.middle} */}
        {/* <Container> */}
        <Row style={{ minWidth: "100%" }}>
          {props?.user ? (
            <h5 style={{ textAlign: "left", paddingBottom: "10px" }}>
              {(props?.user?.firstName || "John") +
                " " +
                (props?.user?.lastName || "Doe")}
            </h5>
          ) : (
            <h5 style={{ textAlign: "left", paddingBottom: "10px" }}>
              John Doe
            </h5>
          )}
          <Col lg={6}>
            <p style={{ fontSize: 14 }}>{props.left}</p>
            <p className="green" style={{ fontSize: "24px" }}>
              {props?.user?.average ? props?.user?.average : 0}
            </p>
          </Col>

          <Col lg={6}>
            <p style={{ fontSize: 14 }}>{props.last}</p>
            <p className="green" style={{ fontSize: "24px" }}>
              {props?.user?.highestCount ? props?.user?.highestCount : 0}
            </p>
          </Col>
          <Col lg={12}>
            <h6 style={{ paddingTop: "15px" }}>Today</h6>
            <div style={{ marginTop: 20 }}>
              <svg width="300" height="100">
                <g>
                  <rect x="10" y="10" width="20" height="20" fill="#ff6347" />
                  <text x="40" y="25" fontSize="14" fill="#fff">
                    Count: 10(Target:{staticVal})
                  </text>
                </g>

                <g>
                  <rect x="10" y="40" width="20" height="20" fill="#00bfff" />
                  <text x="40" y="55" fontSize="14" fill="#fff">
                    Avg. Time: 35 mins(Target: {staticTiming} mins)
                  </text>
                </g>
              </svg>
            </div>
            <DoubleRingDonutChart
              staticData={staticDataOuter}
              dynamicData={staticDataInner}
              chartWidth={chartWidth}
              chartHeight={chartHeight}
              colorCodeValue={colorCodeValue}
            />
          </Col>
        </Row>
        {/* </Container> */}
      </Card>
    </>
  );
};

export default DonutsCard;
// checked
