import { useQuery } from "@tanstack/react-query";
import { endpoints } from "../endpoints/endpoints";
import axiosInstance from "../utilites/axios";
import moment from "moment-timezone";
// limit=${limit * 3}
/**
 * The function `useGetCleaningList` fetches a list of cleaning data with a specified limit using axios
 * and moment.js in a React application.
 * @returns The `useGetCleaningList` function is being exported. It returns the result of the
 * `useQuery` hook with a specific configuration for fetching a cleaning list. The function makes an
 * asynchronous call to an API endpoint using Axios to retrieve cleaning data based on the provided
 * limit and date parameters. The fetched data is then returned if the response status is 200,
 * otherwise an empty array is returned. The
 */
export const useGetCleaningList = ({ limit }) => {
  return useQuery({
    queryKey: ["cleaningList", limit],
    queryFn: async () => {
      return await axiosInstance
        .get(
          `${endpoints.cleaning}?limit=100&date=${moment()
            .tz("America/New_York")
            .format("yyyy-MM-DD")}`
        )
        .then((res) => {
          if (res?.status === 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          // console.log("error", e);
        });
    },
    refetchInterval: 500 * 10,
    onSuccess: ({ data }) => {
      return data;
    },
  });
};

/**
 * The function `useGetCleaningDetails` fetches cleaning details based on a user ID with a specified
 * refetch interval and handles the response accordingly.
 * @returns The `useGetCleaningDetails` function is returning a custom hook that uses `useQuery` from
 * an unspecified library. The hook makes an asynchronous request to fetch cleaning details data from a
 * specific endpoint using Axios. It includes a query key based on the `uid` parameter and the current
 * date in the "America/New_York" timezone. The refetch interval is set to 5000 milliseconds (
 */
export const useGetCleaningDetails = ({ uid }) => {
  return useQuery({
    queryKey: ["cleaningDetails", uid],
    queryFn: async () => {
      return await axiosInstance
        .get(
          `${endpoints.cleaning}/details?uid=${uid}&date=${moment()
            .tz("America/New_York")
            .format("yyyy-MM-DD")}`
        )
        .then((res) => {
          if (res?.status === 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          // console.log(e);
        });
    },
    refetchInterval: 500 * 10,
    onSuccess: ({ data }) => {
      return data;
    },
  });
};
