import { useQuery } from "@tanstack/react-query";
import { endpoints } from "../endpoints/endpoints";
import axiosInstance from "../utilites/axios";
import moment from "moment-timezone";

// export const useGetScreeningList = ({ sortOrder, sortField, date, last }) => {
//   return useInfiniteQuery(
//     "screeningList",
//     async ({ pageParam = 1 }) => {
//       return axiosInstance
//         .get(
//           // `${
//           //   endpoints.screening
//           // }?sortField=firstName&limit=3&sortOrder=asc&last=${last}&date=${moment()
//           //   .tz("America/New_York")
//           //   .format("yyyy-MM-DD")}&previous=${
//           //   pageParam - 1
//           // }&current=${pageParam}`
//           `${
//             endpoints.screening
//           }?sortField=firstName&limit=3&sortOrder=asc&last=${last}&date=2024-04-03&previous=${
//             pageParam - 1
//           }&current=${pageParam}`
//         )
//         .then(({ data }) => {
//           return data;
//         });
//     },
//     {
//       keepPreviousData: true,
//       // enabled: Boolean(currentPage),
//       refetchInterval: 500 * 10,
//       getNextPageParam: (lastPage) => {
//         return lastPage?.users?.length >= 3 ? lastPage?.current + 1 : undefined;
//       },
//     }
//   );
// };
// ${limit * 3}
/**
 * The function `useGetScreeningList` fetches a list of screenings with a specified limit using axios
 * and moment.js in a React application.
 * @returns The `useGetScreeningList` function is returning a custom hook that uses `useQuery` from a
 * library like React Query. This hook fetches a list of screenings from an API endpoint using Axios.
 * It includes a query key based on the limit provided, a query function that makes the API call, a
 * refetch interval of 5000 milliseconds, and an onSuccess callback that returns the data
 */
export const useGetScreeningList = ({ limit }) => {
  return useQuery({
    queryKey: ["screeningList", limit],
    queryFn: async () => {
      return await axiosInstance
        .get(
          `${endpoints.screening}?limit=100&date=${moment()
            .tz("America/New_York")
            .format("yyyy-MM-DD")}`
        )
        .then((res) => {
          if (res?.status === 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          // console.log("error", e);
        });
    },
    refetchInterval: 500 * 10,
    onSuccess: ({ data }) => {
      return data;
    },
  });
};
// ${moment().tz("America/New_York").format("yyyy-MM-DD")}
// 2024-04-09

/**
 * The function `useGetScreeningDetails` fetches screening details using a unique identifier and a
 * specific date, with a refetch interval of 5000 milliseconds, and returns the data upon successful
 * retrieval.
 * @returns The `useGetScreeningDetails` function is returning a custom hook that uses `useQuery` from
 * a library like React Query. This hook fetches screening details data from an API endpoint using
 * Axios. It includes a query key based on the `uid`, a query function that makes the API call, a
 * refetch interval of 5000 milliseconds, and an `onSuccess` callback that returns
 */
export const useGetScreeningDetails = ({ uid }) => {
  return useQuery({
    queryKey: ["screeningDetails", uid],
    queryFn: async () => {
      return await axiosInstance
        .get(
          `${endpoints.screening}/details?uid=${uid}&date=${moment()
            .tz("America/New_York")
            .format("yyyy-MM-DD")}`
        )
        .then((res) => {
          if (res?.status === 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          // console.log(e);
        });
    },
    refetchInterval: 500 * 10,
    onSuccess: ({ data }) => {
      return data;
    },
  });
};
