import { useQuery } from "@tanstack/react-query";
import { endpoints } from "../endpoints/endpoints";
import axiosInstance from "../utilites/axios";
import moment from "moment-timezone";
// limit=${limit * 3}
/**
 * The function `useGetPhotographyList` fetches a list of photography data with a specified limit using
 * axios and moment.js in a React application.
 * @returns The `useGetPhotographyList` function is returning a custom hook that uses `useQuery` from a
 * library like React Query. This hook fetches a list of photography data from an API endpoint using
 * Axios. The query key includes the limit parameter, and the query function makes a GET request to the
 * API endpoint with a limit of 100 and the current date in the "America/New_York
 */
export const useGetPhotographyList = ({ limit }) => {
  return useQuery({
    queryKey: ["photographyList", limit],
    queryFn: async () => {
      return await axiosInstance
        .get(
          `${endpoints.photography}?limit=100&date=${moment()
            .tz("America/New_York")
            .format("yyyy-MM-DD")}`
        )
        .then((res) => {
          if (res?.status === 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          // console.log("error", e);
        });
    },
    refetchInterval: 500 * 10,
    onSuccess: ({ data }) => {
      return data;
    },
  });
};

/**
 * The function `useGetPhotographyDetails` fetches photography details using a specified UID with a
 * refetch interval of 5000 milliseconds.
 * @returns The `useGetPhotographyDetails` function is returning a custom hook that uses `useQuery`
 * from an unknown library. The hook is making an asynchronous request to fetch photography details
 * using Axios, with a specific query key and query function. It also includes a refetch interval of
 * 5000 milliseconds and an `onSuccess` callback that returns the data received from the API call.
 */
export const useGetPhotographyDetails = ({ uid }) => {
  return useQuery({
    queryKey: ["photographyDetails", uid],
    queryFn: async () => {
      return await axiosInstance
        .get(
          `${endpoints.photography}/details?uid=${uid}&date=${moment()
            .tz("America/New_York")
            .format("yyyy-MM-DD")}`
        )
        .then((res) => {
          if (res?.status === 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          // console.log(e);
        });
    },
    refetchInterval: 500 * 10,
    onSuccess: ({ data }) => {
      return data;
    },
  });
};
