import React, { useRef, useEffect } from "react";
import * as d3 from "d3";

const DoubleRingDonutChart = ({
  staticData,
  dynamicData,
  width = 250,
  height = 250,
  colorCodeValue
}) => {
  const svgRef = useRef();

  useEffect(() => {
    const radius = Math.min(width, height) / 2;

    const svg = d3
      .select(svgRef.current)
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${width / 2},${height / 2})`);

    const arcOuter = d3
      .arc()
      .innerRadius(radius - 50)
      .outerRadius(radius - 25);

    const arcInner = d3
      .arc()
      .innerRadius(radius - 90)
      .outerRadius(radius - 70);

    const pie = d3
      .pie()
      .value((d) => d.value)
      .sort(null)
      .padAngle(0);

    svg.selectAll("*").remove();

    const outerArc = svg
      .selectAll(".outer-ring")
      .data(pie(staticData))
      .enter()
      .append("g")
      .attr("class", "outer-ring");

    outerArc
      .append("path")
      .attr("d", arcOuter)
      .attr("fill", (d) => d.data.color)
      .attr("stroke", "#fff")
      .attr("stroke-width", 0);

    const innerArc = svg
      .selectAll(".inner-ring")
      .data(pie(dynamicData))
      .enter()
      .append("g")
      .attr("class", "inner-ring");

    innerArc
      .append("path")
      .attr("d", arcInner)
      .attr("fill", (d) => d.data.color)
      .attr("stroke", "#fff")
      .attr("stroke-width", 0);
  }, [staticData, dynamicData, width, height]);

  return <svg ref={svgRef}></svg>;
};

export default DoubleRingDonutChart;
